//@flow
import languageConfig from 'i18n/languageConfig';
import storageService from 'lib/storageService';
import { changeLanguage } from 'modules/app/actionCreators';
import * as actionTypes from 'modules/app/actionTypes';
import type { AppState } from 'modules/app/typedefs';
import type { Action } from 'typedefs';

const getInitialLanguage = () => {
  const language = storageService.get('language');
  if (language) {
    return language;
  }
  const browserLanguage = navigator.language;
  const isBrowserLanguageSupported =
    languageConfig.findIndex(l => l.languageCode === browserLanguage) >= 0;
  const initialLanguage = isBrowserLanguageSupported ? browserLanguage : 'en';
  storageService.set('language', initialLanguage);
  return initialLanguage;
};

export const initialState: AppState = {
  languageCode: getInitialLanguage(),
};

export default (state: AppState = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        languageCode: action.payload.languageCode,
      };
    default:
      return state;
  }
};

export const getLanguageCode = (state: AppState) => state.languageCode;
export const setLanguageCode = (languageCode: string) => changeLanguage(languageCode);
