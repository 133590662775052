import { useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useContext } from 'react';

const LastLocationContext = React.createContext();

const LastLocationProvider = ({ children }) => {
  const location = useLocation();
  const previousLocation = useRef(location);

  useEffect(() => {
    previousLocation.current = location;
  }, [location]);

  return (
    <LastLocationContext.Provider value={previousLocation.current}>
      {children}
    </LastLocationContext.Provider>
  );
};

const useLastLocation = () => {
  return useContext(LastLocationContext);
};

export { LastLocationProvider, useLastLocation };
