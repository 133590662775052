//@flow
import * as actionTypes from 'modules/app/actionTypes';
import type { ChangeLanguageAction } from 'modules/app/typedefs';
import type { CrudAction } from '../../lib/comms_v2/typeDefs';
import type { MeasuringSite } from '../measurements/typedefs';
import { fetchCollection } from '../../lib/comms_v2/actionCreators';

export const changeLanguage = (languageCode: string): ChangeLanguageAction => ({
  type: actionTypes.CHANGE_LANGUAGE,
  payload: { languageCode },
});

export const getBackendSystemInfo = (): CrudAction<MeasuringSite> => {
  return fetchCollection('counting', `/accounts/system-info/get`);
};
