//@flow
import { jwtDecode } from 'jwt-decode';
import type { IStorageService } from 'typedefs';

const getDecodedToken = (storageService: IStorageService) => {
  const token = storageService.get('token');
  if (token) {
    return jwtDecode(token);
  } else return null;
};

export default getDecodedToken;
