//@flow
var languages = [
  {
    languageCode: 'de',
    locale: 'de-DE',
    name: 'German',
    flag: 'de',
  },
  {
    languageCode: 'en',
    locale: 'en-GB',
    name: 'English',
    flag: 'gb',
  },
  {
    languageCode: 'es',
    locale: 'es-ES',
    name: 'Spanish',
    flag: 'es',
  },
  {
    languageCode: 'fr',
    locale: 'fr-FR',
    name: 'France',
    flag: 'fr',
  },
  {
    languageCode: 'pl',
    locale: 'pl-PL',
    name: 'Polish',
    flag: 'pl',
  },
];

module.exports = languages;
