//@flow
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import storageService from 'lib/storageService';
import ApiClient from 'lib/apiClient';
import rootReducer from 'rootReducer';
import { refreshTokenMiddleware } from 'middleware';
import type { State } from 'typedefs';
import config from 'config';

export const apiClient = new ApiClient({
  basePath: config.system.apiRoot,
  fetchConfig: {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Methods': '*',
    },
  },
  methods: ['get', 'post', 'put', 'patch', 'delete', 'GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
});

apiClient.setAccessToken(storageService.get('token'));

export const history = createBrowserHistory();

const middleware = applyMiddleware(
  refreshTokenMiddleware(apiClient, storageService),
  routerMiddleware(history)
);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (initialState: State) => {
  return createStore(rootReducer, initialState, composeEnhancers(middleware));
};

export default configureStore();
