//@flow
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import appReducer, * as fromApp from 'modules/app/reducers';
import * as authActions from 'modules/auth/actionTypes';
import type { State, Action } from 'typedefs';

const history = createBrowserHistory();

const reducers = combineReducers({
  app: appReducer,
  router: connectRouter(history),
});

const rootReducer = (state: State, action: Action): State => {
  if (action.type === authActions.LOGOUT) {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

export default rootReducer;

export const getLanguageCode = (state: State) => fromApp.getLanguageCode(state.app);
export const setLanguageCode = (languageCode: string) => fromApp.setLanguageCode(languageCode);
