//@flow
import isEmpty from 'lodash/isEmpty';
import { stringify } from 'qs';
import storageService from './storageService';
import { readImageResponse } from './fetch';

class ApiClient {
  accessToken = null;

  setAccessToken(token) {
    this.accessToken = token;
  }

  getAuthHeaders() {
    return this.accessToken ? { Authorization: `Bearer ${this.accessToken}` } : {};
  }

  constructor(passedConfig) {
    const baseConfig = {
      bodyEncoder: JSON.stringify,
      credentials: 'same-origin',
      format: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      methods: ['get', 'post', 'put', 'patch', 'delete'],
    };

    if (!passedConfig.basePath) {
      // e.g. 'https://example.com/api/v3'
      throw new Error('You must pass a base path to the ApiClient');
    }

    const methods = passedConfig.methods || baseConfig.methods;

    methods.forEach((method) => {
      this[method] = (path, { params = {}, data, fetchConfig = {} } = {}): Promise<Response> => {
        const config = {
          ...baseConfig,
          ...passedConfig,
          ...fetchConfig,
          headers: {
            ...baseConfig.headers,
            ...(passedConfig ? passedConfig.headers : {}),
            ...(fetchConfig.headers || {}),
            ...this.getAuthHeaders(),
            'Accept-Language': storageService.get('language'),
          },
        };
        const {
          methods: _methods,
          basePath,
          headers,
          format,
          bodyEncoder,
          ...otherConfig
        } = config;

        const requestPath = (() => {
          // Check if the path is an absolute URL
          if (/^https?:\/\//i.test(path)) {
            if (!path.startsWith(basePath)) {
              throw new Error(
                'The API client is expected to be used with either a relative path or a path that ' +
                  'begins with the basePath variable setting.'
              );
            }

            return path + this.queryString(params);
          }

          // For relative paths, construct the request path directly
          return basePath + path + this.queryString(params);
        })();

        const body = data ? bodyEncoder(data) : undefined;

        if (headers['Content-Type'] === 'multipart/form-data') {
          // see https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
          delete headers['Content-Type'];
        }

        return fetch(requestPath, {
          ...otherConfig,
          method,
          headers,
          body,
        })
          .then((response) => ({ response, format }))
          .then(this.handleErrors)
          .then((response) => {
            if (format === 'image' && response.headers.get('content-type').startsWith('image'))
              return readImageResponse(response);
            return response.status === 204 ? {} : response[format]();
          });
      };
    });
  }

  getMimeType(file) {
    if (file.type.startsWith('image/')) {
      return file.type;
    }
    const ext = (/[.]/.exec(file.name) ? /[^.]+$/.exec(file.name)[0] : '').toLowerCase();
    if (ext === 'png') {
      return 'image/png';
    }
    if (['jpeg', 'jpg'].includes(ext)) {
      return 'image/jpeg';
    }
    return 'application/octet-stream';
  }

  upload(file, url, method = 'put', config = {}) {
    const f = method === 'post' ? this.post : this.put;
    return f(url, {
      data: file,
      fetchConfig: {
        headers: {
          'Content-Type': this.getMimeType(file),
          'Content-Disposition': `attachment; filename="${file.name}"`,
        },
        bodyEncoder: (f) => f,
        ...config,
      },
    });
  }

  queryString(params) {
    return !isEmpty(params) ? `?${stringify(params, { indices: false })}` : '';
  }

  handleErrors({ response, format }) {
    if (!response.ok) {
      return (
        response[format]()
          // if response parsing failed send back the entire response object
          .catch(() => {
            throw response;
          })
          // else send back the parsed error
          .then((parsedErr) => {
            if (format === 'json') {
              throw {
                ...parsedErr,
                responseCode: response.status,
                responseText: response.statusText,
              };
            } else {
              throw parsedErr;
            }
          })
      );
    }
    return response;
  }
}

export default ApiClient;
