// @flow
import { push } from 'connected-react-router';
import storageService from 'lib/storageService';
import store, { apiClient } from 'store';
import * as actionCreators from 'modules/auth/actionCreators';

export const setAccessToken = (accessToken: string, dispatch) => {
  if (!accessToken) return;
  storageService.set('token', accessToken);
  apiClient.setAccessToken(accessToken);
  dispatch(actionCreators.setAccessToken(accessToken));
};

export const setTokenPair = (accessToken: string, refreshToken: string, dispatch) => {
  if (!refreshToken) return;
  storageService.set('refreshToken', refreshToken); // TODO: setting path to refresh endpoint not working in chrome
  setAccessToken(accessToken, dispatch);
};

export const removeTokenPair = () => {
  storageService.remove('token');
  storageService.remove('refreshToken');
  apiClient.setAccessToken();
};

export const logOut = (dispatch, swrCache) => {
  removeTokenPair();
  swrCache && swrCache.clear();
  dispatch(push('/auth/login'));
  dispatch(actionCreators.logout());
};

export const retrievedTokenPair = async (
  accessToken: string,
  refreshToken: string,
  dispatch,
  swrCache
) => {
  setTokenPair(accessToken, refreshToken, dispatch, swrCache);
  swrCache && swrCache.clear();
  dispatch(push('/'));
};

export const swrLogoutOnInvalidTokenErrorHandler = error => {
  if (
    error.responseCode === 401 &&
    error.detail &&
    error.detail.code &&
    error.detail.code === 'token_not_valid'
  ) {
    logOut(store.dispatch);
  }
};
