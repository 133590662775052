// @flow
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

type Props = {
  isAuthenticated: boolean,
  children?: React$Node,
};

const PrivateRoute = ({ isAuthenticated = true, children }: Props) => {
  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  return children ? children : <Outlet />;
};

export default PrivateRoute;
