//@flow
import { jwtDecode } from 'jwt-decode';
import { ROLE_DISTRIBUTOR } from 'lib/constants';
import * as loadables from 'lib/loadables';
import storageService from 'lib/storageService';
import qs from 'qs';
import React, { Suspense, useContext, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import type { JwtToken } from 'typedefs';
import PrivateRoute from '../../../auth/components/PrivateRoute/PrivateRoute';
import { useLastLocation } from './lastLocationContext';
import config from 'config';

export const DecodedJwtTokenContext = React.createContext({});
export const useDecodedJwtToken = () => {
  const ctx: JwtToken = useContext(DecodedJwtTokenContext);
  return {
    userId: ctx.uid,
    organizationId: ctx.oid,
    isDistributor: ctx.role === ROLE_DISTRIBUTOR,
    isSuperuser: ctx.su,
    isOrganizationAdmin: ctx.uid === ctx.oadmin,
    timezone: ctx.tz,
  };
};

const ModalSwitch = () => {
  const token = storageService.get('token');
  const tokenDecoded = useMemo(() => (token ? jwtDecode(token) : null), [token]);
  const isAuthenticated = !!tokenDecoded;

  const location = useLocation();
  const lastLocation = useLastLocation();
  const isModal = !!(location.state && location.state.modal);
  const shouldGoToHome = isAuthenticated && location.pathname === '/auth/login';

  return (
    <DecodedJwtTokenContext.Provider value={tokenDecoded}>
      <Suspense fallback={<Loader active size="large" />}>
        <Routes location={isModal ? lastLocation : location}>
          {shouldGoToHome && <Route path="*" element={<Navigate to="/" />} />}
          <Route path="/auth" element={<loadables.LoadablePublicAppLayout />}>
            <Route
              path="login/oidc/:oidc_provider_id/callback"
              element={<loadables.LoadableOidcLoginScreen />}
            />
            <Route path="login" element={<loadables.LoadableLoginScreen />} />
            <Route path="resetPassword" element={<loadables.LoadableResetPasswordScreen />} />
            <Route
              path="setPassword/:uid/:token"
              element={<loadables.LoadableSetPasswordScreen />}
            />
            <Route path="confirm/:uid/:token" element={<loadables.LoadableActivateUserScreen />} />
          </Route>
          {!isAuthenticated && <Route path="*" element={<Navigate to="/auth/login" />} />}
          {isAuthenticated && (
            <Route path="/" element={<loadables.LoadableAppLayout />}>
              <Route
                path="measurements/new"
                element={
                  <PrivateRoute>
                    <loadables.LoadableCreateMeasurementScreen
                      {...qs.parse(location.search, { ignoreQueryPrefix: true })}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="measurements/:instrumentType/:id"
                element={
                  <PrivateRoute>
                    <loadables.LoadableMeasurementScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="measurements/:instrumentType/:id/complete"
                element={
                  <PrivateRoute>
                    <loadables.LoadableCreateCompleteMeasurementScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="measurements/:instrumentType/:id/edit"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditMeasurementScreen
                      {...qs.parse(location.search, { ignoreQueryPrefix: true })}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="measurements/:instrumentType/:id/create-report"
                element={
                  <PrivateRoute>
                    <loadables.LoadableCreateReportScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="compare-measurements/:instrumentType/:id1/:id2"
                element={
                  <PrivateRoute>
                    <loadables.LoadableCompareMeasurementsScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-sites/new"
                element={
                  <PrivateRoute>
                    <loadables.LoadableCreateMeasuringSiteScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-sites/:id"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditMeasuringSiteScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-instruments/"
                element={
                  <PrivateRoute>
                    <loadables.LoadableMeasuringInstrumentListScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-instruments/new"
                element={
                  <PrivateRoute>
                    <loadables.LoadableCreateMeasuringInstrumentScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditMeasuringInstrumentScreen area="measuringInstrument" />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/configurations"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditMeasuringInstrumentScreen area="configurations" />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/configurations/new"
                element={
                  <PrivateRoute>
                    <loadables.LoadableCreateMeasuringInstrumentConfigScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/configurations/:id"
                element={
                  <PrivateRoute>
                    <loadables.LoadableViewMeasuringInstrumentConfigScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/patches"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditMeasuringInstrumentScreen area="patches" />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/updates"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditMeasuringInstrumentScreen area="updates" />
                  </PrivateRoute>
                }
              />
              <Route
                path="customers"
                element={
                  <PrivateRoute>
                    <loadables.LoadableOrganizationListScreen model="customer" />
                  </PrivateRoute>
                }
              />
              <Route
                path="customers/new"
                element={
                  <PrivateRoute>
                    <loadables.LoadableCreateOrganizationScreen model="customer" />
                  </PrivateRoute>
                }
              />
              <Route
                path="customers/edit/:id"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditOrganizationScreen
                      area="organization"
                      model="customer"
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="customers/edit/:id/accounts"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditOrganizationScreen area="accounts" model="customer" />
                  </PrivateRoute>
                }
              />
              <Route
                path="customers/edit/:id/billing"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditOrganizationScreen area="billing" model="customer" />
                  </PrivateRoute>
                }
              />
              <Route
                path="customers/edit/:organizationId/accounts/edit/:id"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditOrganizationAccountScreen organizationModel="customer" />
                  </PrivateRoute>
                }
              />
              <Route
                path="customers/edit/:organizationId/accounts/new"
                element={
                  <PrivateRoute>
                    <loadables.LoadableCreateOrganizationAccountScreen
                      organizationModel="customer"
                      {...qs.parse(location.search, { ignoreQueryPrefix: true })}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="distributors"
                element={
                  <PrivateRoute>
                    <loadables.LoadableOrganizationListScreen model="distributor" />
                  </PrivateRoute>
                }
              />
              <Route
                path="distributors/new"
                element={
                  <PrivateRoute>
                    <loadables.LoadableCreateOrganizationScreen model="distributor" />
                  </PrivateRoute>
                }
              />
              <Route
                path="distributors/edit/:id"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditOrganizationScreen
                      area="organization"
                      model="distributor"
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="distributors/edit/:id/accounts"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditOrganizationScreen area="accounts" model="distributor" />
                  </PrivateRoute>
                }
              />
              <Route
                path="distributors/edit/:id/billing"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditOrganizationScreen area="billing" model="distributor" />
                  </PrivateRoute>
                }
              />
              <Route
                path="distributors/edit/:organizationId/accounts/edit/:id"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditOrganizationAccountScreen organizationModel="distributor" />
                  </PrivateRoute>
                }
              />
              <Route
                path="distributors/edit/:organizationId/accounts/new"
                element={
                  <PrivateRoute>
                    <loadables.LoadableCreateOrganizationAccountScreen organizationModel="distributor" />
                  </PrivateRoute>
                }
              />
              <Route
                path="user/:id"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditUserScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="user/:id/password"
                element={
                  <PrivateRoute>
                    <loadables.LoadableChangePasswordScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-instruments/:instrumentType/edit/:serial/versions"
                element={
                  <PrivateRoute>
                    <loadables.LoadableEditMeasuringInstrumentScreen area="versions" />
                  </PrivateRoute>
                }
              />
              <Route
                path="measuring-instruments/releases/argos/"
                element={
                  <PrivateRoute>
                    <loadables.LoadableArgosReleasesListScreen
                      {...qs.parse(location.search, { ignoreQueryPrefix: true })}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <loadables.LoadableMapScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="tasks"
                element={
                  <PrivateRoute>
                    <loadables.LoadableTasksListScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="system-info"
                element={
                  <PrivateRoute>
                    <loadables.LoadableBackendSystemInfoScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path="postpaid-billing"
                element={
                  <PrivateRoute>
                    <loadables.LoadablePostpaidBillingScreen />
                  </PrivateRoute>
                }
              />
              {!(isModal && !lastLocation) && (
                <Route path="*" element={<loadables.LoadableNotFound />} />
              )}
            </Route>
          )}
          <Route path="*" element={<loadables.LoadableNotFound />} />
        </Routes>
      </Suspense>
    </DecodedJwtTokenContext.Provider>
  );
};

export default ModalSwitch;
