//@flow
import 'lib/polyfills'; //eslint-disable-line
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'; //eslint-disable-line
import React from 'react'; //eslint-disable-line
import { createRoot } from 'react-dom/client'; //eslint-disable-line
import { Provider } from 'react-redux'; //eslint-disable-line
import * as Sentry from '@sentry/react'; //eslint-disable-line
import Root from 'modules/app/components/Root/Root'; //eslint-disable-line
import store from 'store'; //eslint-disable-line
import 'lib/styles.css'; //eslint-disable-line
import 'semantic-ui-css/semantic.min.css'; //eslint-disable-line
import 'leaflet/dist/leaflet.css'; //eslint-disable-line
import { swrConfig } from './lib/comms_v2/swrConfig'; //eslint-disable-line
import { SWRConfig } from 'swr'; //eslint-disable-line
import releaseInfo from './generatedReleaseInfo.json'; //eslint-disable-line
import config from './config'; //eslint-disable-line
import '@uppy/core/dist/style.css'; //eslint-disable-line
import '@uppy/progress-bar/dist/style.css'; //eslint-disable-line
import '@uppy/status-bar/dist/style.css'; //eslint-disable-line
import '@uppy/dashboard/dist/style.css'; //eslint-disable-line
import 'react-day-picker/dist/style.css'; //eslint-disable-line

Sentry.init({
  dsn: 'https://7f0fd58362b742b682916fb4face8ebb@o1140595.ingest.sentry.io/6198065',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  release: releaseInfo.release,
  environment: config.system.sentryEnvironment,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// const container = document.getElementById('root') || document.createElement('div'); // for testing
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <>
    <div dangerouslySetInnerHTML={{ __html: `<!-- Version: ${releaseInfo.sentryRelease} -->` }} />
    <SWRConfig value={swrConfig}>
      <Provider store={store}>
        <Root />
      </Provider>
    </SWRConfig>
  </>
);
