//@flow
import React from 'react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import ModalSwitch from './ModalSwitch';
import { history } from 'store';
import { LastLocationProvider } from './lastLocationContext';

const Routes = () => (
  <Router history={history}>
    <LastLocationProvider>
      <ModalSwitch />
    </LastLocationProvider>
  </Router>
);

export default Routes;
