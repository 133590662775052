//@flow
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isRequiredIf from 'react-proptype-conditional-require';

import { useTranslators } from './LionessProvider';

type Props = {
  message?: string,
  children?: string,
  messagePlural?: string,
  context?: string,
  count?: number,
};

const T = (props: Props) => {
  const {
    message = null,
    messagePlural = null,
    context = '',
    count = 1,
    children = null,
    ...scope
  } = props;

  const { tcnp, transformInput } = useTranslators();
  const msgid = message || children || '';

  const translatedContent = tcnp(
    context,
    transformInput(msgid),
    transformInput(messagePlural),
    count,
    {
      ...scope,
      count,
    }
  );

  return <Fragment>{translatedContent}</Fragment>;
};

const messagePropType = otherPropName => {
  return isRequiredIf(
    PropTypes.string,
    props => !props[otherPropName],
    `T requires either a message prop or a child node in the form of a pure string`
  );
};

T.propTypes = {
  message: messagePropType('children'),
  children: messagePropType('message'),
  messagePlural: PropTypes.string,
  context: PropTypes.string,
  count: PropTypes.number,
};

export default T;
