export const readImageResponse = (response) => {
  return response.blob().then(
    (blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      })
  );
};

export const fetchImage = async (url, ...options) => {
  const response = await fetch(url, ...options);
  return readImageResponse(response);
};
