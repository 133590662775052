//@flow
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { LionessProvider } from 'lib/lioness';
import messages from 'i18n/messages.json';
import StaticTranslations from 'modules/app/components/StaticTranslations';
import Routes from './Routes';

const Root = () => {
  const languageCode = useSelector((state) => state.app.languageCode);
  return (
    <LionessProvider
      messages={messages}
      locale={languageCode || ''}
      debug={false /*process.env.NODE_ENV === 'development'*/}
    >
      <Fragment>
        <StaticTranslations />
        <Routes />
      </Fragment>
    </LionessProvider>
  );
};

export default Root;
