//@flow
import { useTranslators } from 'lib/lioness';

/**
 * Sometimes there are words which are dynamically handled in a way
 * that `react-gettext-parser` cannot find them.
 * To make them available for translation, these words are noted here.
 */
export default () => {
  const { t } = useTranslators();
  t('morning');
  t('afternoon');
  t('evening');

  t('German');
  t('English');
  t('Begin');
  t('Earliest begin');
  t('End');
  t('Latest end');

  // Date
  t('Sunday');
  t('Monday');
  t('Tuesday');
  t('Wednesday');
  t('Thursday');
  t('Friday');
  t('Saturday');

  t('Sun');
  t('Mon');
  t('Tue');
  t('Wed');
  t('Thu');
  t('Fri');
  t('Sat');

  t('January');
  t('February');
  t('March');
  t('April');
  t('May');
  t('June');
  t('July');
  t('August');
  t('September');
  t('October');
  t('November');
  t('December');

  t('night');
  t('morning');
  t('noon');
  t('evening');

  // Errors
  t('This field may not be blank.');
  t('Enter a valid URL.');

  // Vehicle classes
  t('Bicycle');
  t('Bus');
  t('Car');
  t('Car with trailer');
  t('Motorbike');
  t('Semi-trailer truck');
  t('Transporter with window');
  t('Transporter without window');
  t('Truck');
  t('Truck with trailer');
  t('Passenger car');
  t('Light truck');
  t('Single unit truck');
  t('Combination unit truck');
  t('Pedestrian');

  // Directions
  t('North');
  t('South');
  t('West');
  t('East');
  t('Northwest');
  t('Northeast');
  t('Southwest');
  t('Southeast');

  // Speed classes
  t('v15');
  t('v50');
  t('v85');

  // Form
  t('This field is required.');
  t('Invalid hyperlink - Object does not exist.');
  t('Enter a valid email address.');
  t('This password is too short. It must contain at least 8 characters.');
  t('You do not have permission to perform this action.');
  t(
    'Datetime has wrong format. Use one of these formats instead: YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z].'
  );
  t('Time has wrong format. Use one of these formats instead: hh:mm[:ss[.uuuuuu]].');
  t('This password is entirely numeric.');
  t('This password is too common.');
  t('Max. number of active accounts reached.');
  t('User with this Email address already exists.');
  t('This password is too short. It must contain at least 8 characters.');
  t('You do not have permission to perform this action.');
  t('Values are not unique.');

  // Task states
  t('Succeeded');
  t('In Progress');
  t('Pending');
  t('Failed');
  t('Canceled');
  t('Retrying');

  return null;
};
