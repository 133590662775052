//@flow
import getDecodedToken from 'lib/token';
import type { IStorageService, JwtToken, Action } from 'typedefs';
import { logOut, setAccessToken } from './modules/auth/workflow';
import { Store } from 'redux';
import * as actionTypes from './modules/auth/actionTypes';

class TokenRefreshHandler {
  timeoutId = null;
  dispatch = null;

  constructor() {
    this.timeoutId = null;
  }

  start(dispatch, storageService, apiClient, swrCache) {
    const decoded: JwtToken | null = getDecodedToken(storageService);
    if (!decoded) return;
    const interval = decoded.exp * 1000 - Date.now() - 10000;
    this.timeoutId = setTimeout(() => {
      this.refreshToken(dispatch, storageService, apiClient, swrCache);
    }, interval);
  }

  cancel() {
    if (this.timeoutId === null) return;
    clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }

  isStarted() {
    return this.timeoutId !== null;
  }

  refreshToken = (dispatch, storageService, apiClient, swrCache) => {
    const refreshToken = storageService.get('refreshToken');
    apiClient
      .post('/accounts/token/refresh/', { data: { refresh: refreshToken } })
      .then(result => {
        setAccessToken(result.access, dispatch);
      })
      .catch(e => {
          console.log(e); // eslint-disable-line
        logOut(dispatch, swrCache);
      });
  };
}

const tokenRefreshHandler = new TokenRefreshHandler();

export const refreshTokenMiddleware = (apiClient: *, storageService: IStorageService) => (
  store: Store
) => (next: Function) => (action: Action) => {
  if (action.type === actionTypes.SET_ACCESS_TOKEN) {
    tokenRefreshHandler.cancel();
    tokenRefreshHandler.start(store.dispatch, storageService, apiClient);
  } else if (action.type === actionTypes.LOGOUT) {
    tokenRefreshHandler.cancel();
  } else if (storageService.get('token') && !tokenRefreshHandler.isStarted()) {
    tokenRefreshHandler.start(store.dispatch, storageService, apiClient);
  }
  next(action);
};
