//@flow
import type { Direction, VehicleClass, VehicleGroup } from 'modules/measurements/typedefs';

export const ROLE_DISTRIBUTOR = 1;
export const ROLE_CUSTOMER = 0;

export const INSTRUMENT_TYPES = {
  ARGOS: 'argos',
  SDR: 'sdr',
  AIR_GREEN: 'airgreen',
};

export const WEEKDAYS = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday',
};

export const EXPORT_TIME_INTERVALS = {
  '00:01:00': '1 Min',
  '00:02:00': '2 Min',
  '00:05:00': '5 Min',
  '00:10:00': '10 Min',
  '00:15:00': '15 Min',
  '00:30:00': '30 Min',
  '01:00:00': '60 Min',
};

export const DIRECTIONS: Direction[] = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];

// TODO: Actually now "Object Classes" (Include vehicles and non-vehicles)
export const VEHICLE_CLASSES: VehicleClass[] = [
  'BICYCLE',
  'BUS',
  'CAR',
  'CAR_W_TRAILER',
  'MOTORBIKE',
  'SEMI_TRAILER_TRUCK',
  'TRANSPORTER_W_WINDOW',
  'TRANSPORTER_WO_WINDOW',
  'TRUCK',
  'TRUCK_W_TRAILER',
  'PEDESTRIAN',
  /* US classes */
  'PASSENGER_CAR',
  'LIGHT_TRUCK',
  'SINGLE_UNIT_TRUCK',
  'COMBINATION_UNIT_TRUCK',
];

export const VEHICLE_CLASSES_EUROPE: VehicleClass[] = [
  'BICYCLE',
  'BUS',
  'CAR',
  'CAR_W_TRAILER',
  'MOTORBIKE',
  'SEMI_TRAILER_TRUCK',
  'TRANSPORTER_W_WINDOW',
  'TRANSPORTER_WO_WINDOW',
  'TRUCK',
  'TRUCK_W_TRAILER',
  'PEDESTRIAN',
];

export const VEHICLE_CLASSES_USA: VehicleClass[] = [
  'BICYCLE',
  'BUS',
  'PASSENGER_CAR',
  'MOTORBIKE',
  'LIGHT_TRUCK',
  'SINGLE_UNIT_TRUCK',
  'COMBINATION_UNIT_TRUCK',
  'PEDESTRIAN',
];

export const VEHICLE_CLASSES_COUNTRY_FLAG_EUROPE = 'DEU';
export const VEHICLE_CLASSES_COUNTRY_FLAG_USA = 'USA';

export const VEHICLE_CLASSES_COUNTRY_MAP = {
  [VEHICLE_CLASSES_COUNTRY_FLAG_EUROPE]: VEHICLE_CLASSES_EUROPE,
  [VEHICLE_CLASSES_COUNTRY_FLAG_USA]: VEHICLE_CLASSES_USA,
};

export const VEHICLE_GROUPS: VehicleGroup[] = [
  'VEHICLE_GROUP_1',
  'VEHICLE_GROUP_2',
  'VEHICLE_GROUP_3',
];

export const VEHICLE_CLASSES_READABLE = {
  BICYCLE: 'Bicycle',
  BUS: 'Bus',
  CAR: 'Car',
  CAR_W_TRAILER: 'Car with trailer',
  MOTORBIKE: 'Motorbike',
  SEMI_TRAILER_TRUCK: 'Semi-trailer truck',
  TRANSPORTER_W_WINDOW: 'Transporter with window',
  TRANSPORTER_WO_WINDOW: 'Transporter without window',
  TRUCK: 'Truck',
  TRUCK_W_TRAILER: 'Truck with trailer',
  PEDESTRIAN: 'Pedestrian',
  /* US classes */
  PASSENGER_CAR: 'Passenger car',
  LIGHT_TRUCK: 'Light truck',
  SINGLE_UNIT_TRUCK: 'Single unit truck',
  COMBINATION_UNIT_TRUCK: 'Combination unit truck',
};
export const VEHICLE_CLASSES_READABLE__PLURALS = {
  BICYCLE: 'Bicycles',
  BUS: 'Busses',
  CAR: 'Cars',
  CAR_W_TRAILER: 'Cars with trailer',
  MOTORBIKE: 'Motorbikes',
  SEMI_TRAILER_TRUCK: 'Semi-trailer trucks',
  TRANSPORTER_W_WINDOW: 'Transporters with window',
  TRANSPORTER_WO_WINDOW: 'Transporters without window',
  TRUCK: 'Trucks',
  TRUCK_W_TRAILER: 'Trucks with trailer',
  PEDESTRIAN: 'Pedestrians',
  /* US classes */
  PASSENGER_CAR: 'Passenger cars',
  LIGHT_TRUCK: 'Light trucks',
  SINGLE_UNIT_TRUCK: 'Single unit trucks',
  COMBINATION_UNIT_TRUCK: 'Combination unit trucks',
};

export const ObjectClasses = {
  MOTOR_VEHICLE: 'MOTOR_VEHICLE',
  BICYCLE: 'BICYCLE', // TODO: Possibly rename to "Manual Vehicles"
  PEDESTRIAN: 'PEDESTRIAN',
};

export const NON_VEHICLE_CLASSES = [ObjectClasses.PEDESTRIAN, ObjectClasses.BICYCLE];
export const ONLY_VEHICLE_CLASSES = VEHICLE_CLASSES.filter(
  (vc) => !NON_VEHICLE_CLASSES.includes(vc)
);

export const DIRECTIONS_READABLE = {
  N: 'North',
  S: 'South',
  W: 'West',
  E: 'East',
  NW: 'Northwest',
  SW: 'Southwest',
  NE: 'Northeast',
  SE: 'Southeast',
};

export const COLORS = [
  'red',
  'olive',
  'orange',
  'green',
  'teal',
  'purple',
  'pink',
  'brown',
  'grey',
  'violet',
  'blue',
  'yellow',
  'black',
];

export const VEHICLE_CLASSES_COLORS = {
  BICYCLE: 'red',
  BUS: 'olive',
  CAR: 'orange',
  CAR_W_TRAILER: 'green',
  MOTORBIKE: 'teal',
  SEMI_TRAILER_TRUCK: 'purple',
  TRANSPORTER_W_WINDOW: 'pink',
  TRANSPORTER_WO_WINDOW: 'brown',
  TRUCK: 'grey',
  TRUCK_W_TRAILER: 'violet',
  PEDESTRIAN: 'blue',
  /* US classes */
  PASSENGER_CAR: 'orange',
  LIGHT_TRUCK: 'grey',
  SINGLE_UNIT_TRUCK: 'pink',
  COMBINATION_UNIT_TRUCK: 'violet',
  /* Custom groups */
  VEHICLE_GROUP_1: 'yellow',
  VEHICLE_GROUP_2: 'black',
  VEHICLE_GROUP_3: 'blue', // TODO: blue is used twice. Semantic-ui label only allows 13 colors: https://react.semantic-ui.com/elements/label/#variations-colored
};

export const VIDEO_QUALITY_SETTINGS = {
  LOW: 'low',
  HIGH: 'high',
  ULTRA_HIGH: 'ultra_high',
};

export const USER_TASK_STATES = {
  in_progress: 'In Progress',
  succeeded: 'Succeeded',
  failed: 'Failed',
  retrying: 'Retrying',
  canceled: 'Canceled',
  pending: 'Pending',
};

export const OperationModes = {
  mobile: 'mobile',
  mobileOnline: 'mobile_online',
  permanent: 'permanent',
  nearRealtime: 'near-realtime',
  mobileLTEMYTD: 'mobile_lte_mytd',
};

export const CurrencyCodeVerboseNames = {
  EUR: 'Euro',
  USD: 'United States Dollar',
  CAD: 'Canadian Dollar',
  GBP: 'British Pound',
  MXN: 'Mexican Peso',
};

export const CurrencyCodeSymbols = {
  EUR: '€',
  USD: '$',
  CAD: '$',
  GBP: '£',
  MXN: '$',
};
