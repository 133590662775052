//@flow
import type { Config } from './typedefs';

const config: Config = {
  map: {
    tileLayerUrl: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '© OpenStreetMap contributors | <a id="terms" class="termsLink" target="_blank" href="http://www.openstreetmap.org/copyright">Terms</a>',
    geocoderQueryTemplate:
      'https://nominatim.openstreetmap.org/search?q={query}&addressdetails=true&format=json',
  },
  system: {
    apiRoot: process.env.REACT_APP_API_ROOT
      ? process.env.REACT_APP_API_ROOT
      : `https://api.${window.location.host.replace('www.', '')}/v1`,
    fileUploadRoot: process.env.REACT_APP_UPLOAD_ROOT
      ? process.env.REACT_APP_UPLOAD_ROOT
      : `https://tus.${window.location.host.replace('www.', '')}/files/`,
    grafanaDashboardHost: process.env.REACT_APP_GRAFANA_HOST
      ? process.env.REACT_APP_UPLOAD_ROOT
      : `revpro.${window.location.host.replace('www.', '')}`,
    sentryEnvironment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  },
};

export default config;
