// @flow
import { apiCaller } from './apiCaller';
import { swrLogoutOnInvalidTokenErrorHandler } from 'modules/auth/workflow';

export const swrConfig = {
  revalidateOnMount: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: true,
  shouldRetryOnError: true,
  errorRetryInterval: 5000,
  errorRetryCount: 3,
  fetcher: apiCaller,
  onError: swrLogoutOnInvalidTokenErrorHandler,
};
